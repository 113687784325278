import React from "react";
import { Link } from "react-router-dom";
import Post from "../assets/post.webp";
import Mongo from "../assets/mongo.webp";
import Firebase from "../assets/firebase.webp";
import Sqlite from "../assets/Sqlite.webp";
import ScrollToTop from "./ScrollToTop";
import SQLpeeps from "../assets/sqlpeeps.webp";
import { textVariant } from "../utils/motion.jsx";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const SQL = () => {
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: false,
    threshold: 0.7,
  });
  return (
    <div
      className="bg-neutral-800 via-transparent border-b-4 border-teal-500"
      style={{ marginTop: "70px" }}
    >
      <ScrollToTop />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-10 md:py-24 space-y-8">
        <div className="max-w-3xl text-center mx-auto">
          <h1 className="block font-medium text-gray-200 text-4xl sm:text-5xl md:text-6xl lg:text-7xl">
            You have come to the right place!
          </h1>
        </div>

        <div className="max-w-3xl text-center mx-auto mb-10 md:mb-20">
          <p className="text-lg text-gray-400">
            Let{" "}
            <strong className="text-yellow-500">
              Eagle Engineering Systems
            </strong>{" "}
            help you with your SQL Database Management needs. We can help with
            database optimization, and streamline queries. With so many options
            available,{" "}
            <stong className="text-white">
              we can help you choose the best database for your project.
            </stong>
          </p>
        </div>
        <div className="max-w-6xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="relative rounded-xl overflow-hidden">
            <img
              src={SQLpeeps}
              alt="Group"
              className="min-h-[35vh] md:min-h-[75vh] w-full object-cover"
            />
            <div className="absolute bottom-0 start-0 end-0 max-w-xs text-center mx-auto p-6 md:start-auto md:text-start md:mx-0">
              <div className="px-5 py-4 inline-block bg-neutral-800 bg-opacity-75 rounded-lg md:p-7"></div>
            </div>
          </div>
        </div>

        <motion.div
          ref={ref1}
          initial="hidden"
          animate={inView1 ? "show" : "hidden"}
          variants={textVariant()}
        >
          <div className="flex justify-center">
            <Link
              className="group mt-10 mb-10 inline-block bg-neutral-200/10 hover:bg-neutral-200/10 border border-white/10 p-1 ps-4 rounded-full shadow-md focus:outline-none focus:ring-1 focus:ring-gray-600"
              to="/contact"
            >
              <p className="me-2 inline-block text-white text-lg">
                SQL Database Management begins{" "}
                <strong className="text-yellow-400">HERE</strong> 🚀
              </p>
            </Link>
          </div>
        </motion.div>
        {/* Database Options */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 opacity-80">
          <DatabaseOption
            image={Post}
            alt="PostgreSQL"
            title="PostgreSQL"
            description="Open-source relational database system."
          />
          <DatabaseOption
            image={Mongo}
            alt="MongoDB"
            title="MongoDB"
            description="NoSQL database, JSON like documents."
          />
          <DatabaseOption
            image={Firebase}
            alt="Firebase"
            title="Firebase"
            description="Developed by Google, real-time database."
          />
          <DatabaseOption
            image={Sqlite}
            alt="SQLite"
            title="SQLite"
            description="A C library, lightweight disk-based database."
          />
        </div>

        {/* End Database Options */}
      </div>
    </div>
  );
};

const DatabaseOption = ({ image, alt, title, description }) => {
  return (
    <div className="flex flex-col items-center justify-center mb-4">
      <img
        src={image}
        className="w-16 h-16 mb-4 hover:scale-105 transition-transform duration-500 ease-in-out"
        alt={alt}
      />
      <h2 className="font-medium text-white text-lg mb-1">{title}</h2>
      <p className="text-gray-300 text-sm text-center">{description}</p>
    </div>
  );
};

export default SQL;
