import React from "react";
import logo from "../assets/footer.webp";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer class="relative overflow-hidden bg-neutral-800 border-t-2 border-neutral-600">
      <svg
        class="absolute -bottom-20 start-1/2 w-[1900px] transform -translate-x-1/2"
        width="2745"
        height="488"
        viewBox="0 0 2745 488"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 330.864C232.505 403.801 853.749 527.683 1482.69 439.719C2111.63 351.756 2585.54 434.588 2743.87 487"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 308.873C232.505 381.81 853.749 505.692 1482.69 417.728C2111.63 329.765 2585.54 412.597 2743.87 465.009"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 286.882C232.505 359.819 853.749 483.701 1482.69 395.738C2111.63 307.774 2585.54 390.606 2743.87 443.018"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 264.891C232.505 337.828 853.749 461.71 1482.69 373.747C2111.63 285.783 2585.54 368.615 2743.87 421.027"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 242.9C232.505 315.837 853.749 439.719 1482.69 351.756C2111.63 263.792 2585.54 346.624 2743.87 399.036"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 220.909C232.505 293.846 853.749 417.728 1482.69 329.765C2111.63 241.801 2585.54 324.633 2743.87 377.045"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 198.918C232.505 271.855 853.749 395.737 1482.69 307.774C2111.63 219.81 2585.54 302.642 2743.87 355.054"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 176.927C232.505 249.864 853.749 373.746 1482.69 285.783C2111.63 197.819 2585.54 280.651 2743.87 333.063"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 154.937C232.505 227.873 853.749 351.756 1482.69 263.792C2111.63 175.828 2585.54 258.661 2743.87 311.072"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 132.946C232.505 205.882 853.749 329.765 1482.69 241.801C2111.63 153.837 2585.54 236.67 2743.87 289.082"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 110.955C232.505 183.891 853.749 307.774 1482.69 219.81C2111.63 131.846 2585.54 214.679 2743.87 267.091"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 88.9639C232.505 161.901 853.749 285.783 1482.69 197.819C2111.63 109.855 2585.54 192.688 2743.87 245.1"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 66.9729C232.505 139.91 853.749 263.792 1482.69 175.828C2111.63 87.8643 2585.54 170.697 2743.87 223.109"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 44.9819C232.505 117.919 853.749 241.801 1482.69 153.837C2111.63 65.8733 2585.54 148.706 2743.87 201.118"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 22.991C232.505 95.9276 853.749 219.81 1482.69 131.846C2111.63 43.8824 2585.54 126.715 2743.87 179.127"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
        <path
          d="M0.5 1C232.505 73.9367 853.749 197.819 1482.69 109.855C2111.63 21.8914 2585.54 104.724 2743.87 157.136"
          class="stroke-neutral-600"
          stroke="currentColor"
        />
      </svg>

      <div class="relative z-10 py-14 flex justify-between items-center w-full px-4 sm:px-8 md:px-16 lg:px-24 mx-auto">
        <div class="inline-flex items-center">
          <img
            onClick={scrollToTop}
            src={logo}
            alt="logo"
            class="w-16 h-16 cursor-pointer"
          />
          <div class="border-s  border-neutral-200 ps-5 ms-5">
            <p class="text-sm text-neutral-300">
              © 2024 <strong>Eagle Engineering</strong> <br></br>
              <a className="hover:text-blue-400" href="tel:(714) 419-9160">
                (714) 419-1270
              </a>
              <span style={{ margin: "0 5px" }}></span>
              {/* <a
                className="hover:text-blue-400"
                href="mailto:escobarjeremy@gmail.com"
              >
                info@eaglees.live
              </a> */}
            </p>
            <p class="text-sm text-neutral-300">Huntington Beach, CA 92649</p>
          </div>
        </div>

        {/* LinkedIn Icon aligned to the right */}
        <a
          href="https://www.linkedin.com/company/eagle-engineering-systems"
          target="_blank"
          rel="noopener noreferrer"
          class="w-20 h-auto hidden sm:block text-teal-600"
        >
          <svg
            viewBox="0 0 267.515 65.247"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path
              d="M263.043 56.411a4.418 4.418 0 1 0 .085 0zm0 8.33a3.874 3.874 0 1 1 3.809-3.938v.065a3.791 3.791 0 0 1-3.708 3.871h-.1m-16.96-9.535h-9.6V40.17c0-3.585-.064-8.2-4.993-8.2-5 0-5.765 3.906-5.765 7.939v15.294h-9.6V24.287h9.216v4.225h.129a10.1 10.1 0 0 1 9.093-4.994c9.73 0 11.524 6.4 11.524 14.726zm-40.79-35.143a5.571 5.571 0 1 1 5.57-5.572 5.571 5.571 0 0 1-5.57 5.572m4.8 35.143h-9.61V24.287h9.61zM250.87.004h-55.21a4.728 4.728 0 0 0-4.781 4.67v55.439a4.731 4.731 0 0 0 4.781 4.675h55.21a4.741 4.741 0 0 0 4.8-4.675V4.67a4.738 4.738 0 0 0-4.8-4.67m-86.12 31.749c-4.8 0-7.68 3.205-7.68 7.875s2.879 7.878 7.68 7.878 7.687-3.2 7.687-7.878-2.881-7.875-7.687-7.875m16.525 23.437h-8.838v-4.1h-.131a12.071 12.071 0 0 1-9.544 4.868c-9.224 0-15.3-6.657-15.3-16.071s7.685-16.585 14.216-16.585c3.973 0 7.684 1.087 9.861 4.1h.126V9.577h9.609zm-46.139-19.048a5.756 5.756 0 0 0-5.894-5.89 6.406 6.406 0 0 0-6.784 5.89zm8.132 13.7a16.909 16.909 0 0 1-13.128 6.151c-9.6 0-17.286-6.408-17.286-16.331s7.685-16.328 17.286-16.328c8.973 0 14.6 6.4 14.6 16.328v3.01h-22.282a7.171 7.171 0 0 0 7.235 6.019 8.193 8.193 0 0 0 6.851-3.778zM47.834 24.279h9.219v4.225h.131a10.085 10.085 0 0 1 9.09-4.994c9.735 0 11.527 6.405 11.527 14.726V55.19h-9.6V40.159c0-3.588-.066-8.2-5-8.2-4.99 0-5.76 3.907-5.76 7.939v15.288h-9.6zM82.669 9.58h9.6v27.265l10.88-12.583h11.77l-12.6 14.313 12.335 16.63h-12.066L92.397 39.923h-.126v15.28h-9.6zM32.911 24.276h9.6v30.916h-9.6zm4.8-15.37a5.569 5.569 0 1 1-5.57 5.569 5.569 5.569 0 0 1 5.57-5.569M0 9.587h9.993v36.4h18.5v9.222H0zm263.744 51.522a1.2 1.2 0 0 0 1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025 1.291 2.029h.724l-1.389-2.1zm-.783-.472h-.785v-1.593h.995c.514 0 1.1.084 1.1.757 0 .774-.593.836-1.314.836"
              fill="currentColor"
            />{" "}
          </svg>
        </a>
      </div>
    </footer>
  );
}
