import React, { useState, useEffect } from "react";

const WebFrame = ({ url }) => {
  const initialWidth = window.innerWidth < 768 ? "400px" : "1100px";
  const [iframeWidth, setIframeWidth] = useState(initialWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (mobile) {
        setIframeWidth("400px");
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const setDesktopView = () => {
    if (!isMobile) {
      setIframeWidth("1100px");
    }
  };

  const setMobileView = () => {
    if (!isMobile) {
      setIframeWidth("400px");
    }
  };

  return (
    <div className="flex flex-col items-center h-screen ">
      <h1 className="text-2xl text-center font-bold text-teal-500 mt-10 mb-4">
        "LLM Demo with Flask App"{" "}
      </h1>
      <div className="text-center text-gray-500 mb-8">
        <p>
          This is a demo of a Flask app that uses{" "}
          <strong>Google's PaLM2</strong> model to generate text. The app is
          hosted on Vercel.
        </p>
      </div>
      {!isMobile && (
        <div className="flex space-x-4 mb-4">
          <button onClick={setDesktopView}>
            {/* Mobile Icon SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10 text-neutral-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
              />
            </svg>
          </button>
          <button onClick={setMobileView}>
            {/* Desktop Icon SVG */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-10 h-10 text-neutral-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
              />
            </svg>
          </button>
        </div>
      )}
      <div
        id="iframe-container"
        className="relative"
        style={{ width: iframeWidth }}
      >
        <iframe
          src={url}
          title="Web Frame"
          className="w-full h-full border-4 border-teal-400 rounded-2xl"
          style={{ minHeight: "80vh" }}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

function App() {
  const url = "https://flask-hello-world-jge162s-projects.vercel.app/";

  return (
    <div className="App mt-40 rounded-2xl">
      <WebFrame url={url} />

      <div className="flex justify-center  border-b-4 border-teal-500 items-center">
        <div className="flex mb-20 mt-10 space-x-4"></div>
      </div>
    </div>
  );
}

export default App;
