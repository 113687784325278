export const textVariant = () => {
  return {
    hidden: {
      y: -50, // Moves the element 50px up
      opacity: 0, // Makes the element fully transparent
    },
    show: {
      y: 0, // Moves the element back to its original position
      opacity: 1, // Makes the element fully opaque
      transition: {
        type: "spring",
        duration: 1.25,
      },
    },
  };
};

export const fadeIn = (direction, type, delay, duration) => {
  return {
    hidden: {
      x: direction === "left" ? 100 : direction === "right" ? -100 : 0,
      y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
      opacity: 0,
    },
    show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        type: type,
        delay: delay,
        duration: duration,
        ease: "easeOut",
      },
    },
  };
};

export const zoomIn = (delay, duration) => {
  return {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    show: {
      scale: 1,
      opacity: 1,
      transition: {
        type: "tween",
        delay: delay,
        duration: duration,
        ease: "easeOut",
      },
    },
  };
};

export const slipIn = (direction, type, delay, duration) => {
  const isDesktop = window.innerWidth > 768;

  if (!isDesktop) {
    // For mobile view, return no animation
    return {
      hidden: { opacity: 1, x: 0 },
      show: { opacity: 1, x: 0 },
    };
  }

  let initialX = "0%";
  if (direction === "right") {
    initialX = "100%";
  } else if (direction === "left") {
    initialX = "-100%";
  }

  return {
    hidden: {
      x: initialX,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        type: type,
        delay: delay,
        duration: duration,
        ease: "easeOut",
      },
    },
  };
};

export const staggerContainer = (staggerChildren, delayChildren) => {
  return {
    hidden: {},
    show: {
      transition: {
        staggerChildren: staggerChildren,
        delayChildren: delayChildren || 0,
      },
    },
  };
};

export const hoverScale = {
  hover: {
    scale: 1.05, // Scales up the element to 105% of its original size on hover
    transition: {
      type: "spring", // Uses a spring animation for a smooth effect
      stiffness: 300, // Adjust the stiffness for the spring animation
    },
  },
};
