import React from "react";
import ScrollToTop from "./ScrollToTop";

import CODE from "../assets/code.webp";

const Embedded = () => {
  return (
    <div className="mt-40 mx-auto text-center border-b-4 border-teal-400">
      <ScrollToTop />
      <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl ">
        Embedded Programming
        <span class="text-teal-500">
          {" "}
          <br></br>know-how!
        </span>{" "}
      </h1>
      <p class="text-lg font-normal lg:text-xl text-gray-400">
        Let Eagle Engineering Systems help you with your embedded projects.
      </p>
      <div className="">
        <h2 class=" text-lg mt-6 font-extrabold leading-none tracking-tight text-teal-500 md:text-lg lg:text-lg mb-6">
          DAC, Piano Embedded Project.
        </h2>
        <div className="flex justify-center items-center px-4">
          {" "}
          {/* Added padding and flexbox alignment */}
          <img
            src={CODE}
            alt=""
            className="h-full w-full sm:w-1/2 rounded-2xl mx-auto mb-20"
          />
        </div>
      </div>

      {/* <hr className="h-[0.5] my-16" /> */}
    </div>
  );
};
export default Embedded;
