import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import wallpaperImage from "../assets/git.webp";
import ScrollToTop from "./ScrollToTop";

// Hardcoded values for testing
const SERVICE_ID = "service_nm65skr";
const TEMPLATE_ID = "template_mys2plr"; // Template ID from emailjs template_mys2plr template_wtsqpyn
const USER_ID = "9ERJ5j4yIoGe9Uxpq";

/* 
emailjs.send("service_nm65skr","template_wtsqpyn",{
email: "escobarjeremy@gmail.com",
message: "test",
});
*/

export default function ContactUs() {
  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    // Retrieve form values
    const firstName = form.current["first-name"].value;
    const lastName = form.current["last-name"].value;
    const email = form.current["email"].value;
    const phoneNumber = form.current["phone-number"].value;
    const message = form.current["message"].value;

    // Check that all required fields are filled out
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !message /* other fields... */
    ) {
      toast.error("All fields must be filled out.");
      return; // Exit function if not all fields are filled out
    }

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
      () => {
        toast.success("Message sent successfully! We'll be in touch.");
        form.current.reset();
        navigate("/");
      },
      () => {
        toast.error(
          "Something went wrong. Please try again or email us directly"
        );
      }
    );
  };

  return (
    <div
      className="relative isolate bg-neutral-800 "
      style={{ marginTop: "4px" }}
    >
      <ScrollToTop />
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2">
              <div
                className="relative w-full h-full"
                style={{ marginTop: "80px" }}
                aria-hidden="true"
              >
                <div
                  className="absolute inset-0 bg-center bg-cover aspect-w-1155 aspect-h-678 sm:aspect-w-auto sm:aspect-h-auto"
                  style={{
                    backgroundImage: `url(${wallpaperImage})`, // Use the imported image
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <form
          ref={form}
          onSubmit={sendEmail}
          className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
        >
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <h2 className="text-3xl font-bold tracking-tight text-teal-500">
                  Book a Meeting today!
                </h2>
                <p className=" text-sm leading-8 text-gray-100 mb-10">
                  By submitting your inquiry, you acknowledge that we may call
                  or email you to discuss the job in more detail. Our response
                  time for inquiries is typically within 24 to 48 hours.
                </p>

                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-semibold leading-6 text-gray-100"
                    >
                      First name
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 dark:text-gray-100 bg-white/5 px-3.5 py-2 text-gray-700 shadow-sm ring-1 ring-inset ring-gray/10 focus:ring-2 focus:ring-inset focus:ring-blue-700 focus:outline-none sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-semibold leading-6 text-gray-100"
                    >
                      Last name
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0  bg-white/5 px-3.5 py-2 text-gray-100 shadow-sm ring-1 ring-inset ring-gray/10 focus:ring-2 focus:ring-inset focus:ring-blue-700 focus:outline-none sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-semibold leading-6 text-gray-100"
                >
                  Company name
                </label>
                <div className="mt-2.5">
                  <input
                    type="company"
                    name="company"
                    id="company"
                    autoComplete="company"
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-gray-100 shadow-sm ring-1 ring-inset ring-gray/10 focus:ring-2 focus:ring-inset focus:ring-blue-700 focus:outline-none sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-2 mt-5">
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 text-gray-100"
              >
                Email
              </label>
              <div className="mt-2.5">
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0  bg-white/5 px-3.5 py-2 text-gray-100 shadow-sm ring-1 ring-inset ring-gary/10 focus:ring-2 focus:ring-inset focus:ring-blue-700 focus:outline-none sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2 mt-5">
              <label
                htmlFor="phone-number"
                className="block text-sm font-semibold leading-6 text-gray-100"
              >
                Phone number
              </label>
              <div className="mt-2.5">
                <input
                  type="tel"
                  name="phone-number"
                  id="phone-number"
                  autoComplete="tel"
                  className="block w-full rounded-md border-0  bg-white/5 px-3.5 py-2 text-gray-100 shadow-sm ring-1 ring-inset ring-gray/10 focus:ring-2 focus:ring-inset focus:ring-blue-700 focus:outline-none sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2 mt-5">
              <label
                htmlFor="message"
                className="block text-sm font-semibold leading-6 text-gray-100"
              >
                Project Details
              </label>
              <div className="mt-2.5">
                <textarea
                  name="message"
                  id="message"
                  rows={4}
                  className="block w-full rounded-md border-0  bg-white/5 px-3.5 py-2 text-gray-100 shadow-sm ring-1 ring-inset ring-gray/10 focus:ring-2 focus:ring-inset focus:ring-blue-700 focus:outline-none sm:text-sm sm:leading-6"
                  defaultValue={""}
                />
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className="bg-teal-700  text-white px-4 py-2 rounded hover:bg-teal-900 sm:mr-12"
              >
                Send Inquery
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
