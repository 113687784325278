import React from "react";
import { Link } from "react-router-dom";
// import Graph from "../assets/graph.webp";
// import SQL from "../assets/sql.webp";
// //import System from "../assets/hardware.png";
// //import Agile from "../assets/agile.webp";
// import Data from "../assets/data.webp";
import { textVariant } from "../utils/motion.jsx";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import ML from "../assets/ml.webp";
import Bolt from "../assets/bolt.webp";
import Band from "../assets/band.png";
const cards = [
  // {
  //   src: Graph,
  //   alt: "Graph Description",
  //   title: "Data Visualization",
  //   description:
  //     "Eagle transforms complex data into compelling graphical representations, enabling clear decision-making.",
  //   link: "/",
  //   badges: ["Python", "Pandas", "Matplotlib", "Sqlite3"], // badges
  // },
  // {
  //   src: SQL,
  //   alt: "SQL Description",
  //   title: "SQL Database Management",
  //   description:
  //     "Eagle enhances your data infrastructure through advanced SQL management, optimizing performance and scalability.",
  //   link: "/",
  //   badges: ["Firebase", "SQLite", "MongoDB", "PostgreSQL"], // badges
  // },
  /*{
    src: System,
    alt: "System Description",
    title: "Embedded Systems",
    description:
      "From hardware design to software integration, Eagle delivers dependable embedded systems solutions.",
    link: "/",
    badges: ["Embedded C", "Microcontrollers", "CCS", "GPIO"], // badges
  },*/
  // {
  //   src: Data,
  //   alt: "Data Mining",
  //   title: "Data Mining",
  //   description:
  //     "Eagle extracts actionable insights from raw data, utilizing sophisticated mining techniques to drive business growth.",
  //   link: "/",
  //   badges: ["Python", "Pandas", "Sklearn", "Numpy", "ML"], // badges
  // },
  // {
  //   src: ML,
  //   alt: "Machine Learning",
  //   title: "Machine Learning",
  //   description:
  //     "Eagle crafts cutting-edge machine learning solutions, focusing on predictive accuracy and algorithmic efficiency.",
  //   link: "/",
  //   badges: ["Kmeans", "Dbscan", "Entropy", "SSE", "Purity"], // badges
  // },

  {
    src: Bolt,
    alt: "Circuit Design",
    title: "Circuit Design",
    description:
      "We create reliable and efficient circuit designs for various electronic applications.",
    link: "/",
    badges: ["Multisim", "LTspice", "Oscilloscope"], // badges
  },

  {
    src: Band,
    alt: "Embedded Systems Development",
    title: "Embedded Systems",
    description:
      "Developing embedded systems that integrate hardware and software.",
    link: "/",
    badges: ["Microcontrollers", "C Programming"],
  },

  // {
  //   src: Bolt,
  //   alt: "Power Systems Engineering",
  //   title: "Power Systems Engineering",
  //   description:
  //     "Delivering efficient and robust power solutions for renewable energy systems, power grids, and industrial applications.",
  //   link: "/",
  //   badges: ["MATLAB", "LTpice"],
  // },
];

const App = () => {
  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: false,
    threshold: 0.7,
  });

  return (
    <div className="bg-neutral-800 py-16">
      <div className="max-w-6xl px-4 py-20 sm:px-6 lg:px-8 lg:py-20 mx-auto">
        <motion.div
          ref={ref1}
          initial="hidden"
          animate={inView1 ? "show" : "hidden"}
          variants={textVariant()}
        >
          <div className="flex items-center mb-8">
            <div className="border-l-4 border-teal-400 h-12 mr-2"></div>
            <span className="uppercase text-sm text-gray-100">
              What we offer
            </span>
          </div>
        </motion.div>
        <div className="flex justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 sm:gap-20">
            {cards.map((card, index) => (
              <Link
                key={index}
                to={card.link}
                className="group relative block overflow-hidden  p-4 "
              >
                <div className="flex justify-center mb-4">
                  <img
                    className="hover:scale-95 transition-transform duration-500 ease-in-out object-contain h-48 w-full"
                    src={card.src}
                    alt={card.alt}
                  />
                </div>
                <div className="text-gray-300">
                  <h2 className="text-lg font-bold mb-2">{card.title}</h2>
                  <p className="text-sm opacity-70 mb-4">{card.description}</p>
                  <div className="flex flex-wrap gap-2">
                    {card.badges.map((badge, badgeIndex) => (
                      <span
                        key={badgeIndex}
                        className="text-xs font-medium px-2.5 py-0.5 rounded bg-neutral-700 text-gray-300 border border-gray-400"
                      >
                        {badge}
                      </span>
                    ))}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
