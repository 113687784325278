import { useEffect, useState } from "react";
import "animate.css/animate.css";
import Graph from "../assets/graph2.png";
import Scatter from "../assets/scatter.webp";
import PieChart from "../assets/piechart.webp";
import Mosaic from "../assets/mosaic.webp";

import ScrollToTop from "./ScrollToTop";

const servicesData = [
  {
    id: 1,
    image: Graph,
    title: "Bar Graph",
    description:
      "Bar graphs simplify data comparison across categories, highlighting trends and disparities effectively.",
  },
  {
    id: 2,
    image: Scatter,
    title: "Scatter Plot",
    description:
      "Scatter plots reveal correlations and outliers within datasets, aiding in predictive analysis and trend identification.",
  },
  {
    id: 4,
    image: PieChart,
    title: "Pie Chart Graph",
    description:
      "Pie charts offer a quick visual distribution snapshot, ideal for understanding proportionate data and segment dominance.",
  },
  {
    id: 5,
    image: Mosaic,
    title: "Mosaic Graph",
    description:
      "Mosaic graphs excel at displaying complex categorical data, illustrating interactions and comparative densities.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Services = () => {
  const [animationClass, setAnimationClass] = useState("opacity-0"); // Start with 0 opacity

  useEffect(() => {
    // Add a 1-second delay before applying the animation
    const animationTimeout = setTimeout(() => {
      setAnimationClass("opacity-100 animate__animated animate__fadeInUp");
    }, 500);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(animationTimeout);
  }, []);

  return (
    <div
      className="bg-neutral-800 border-b-4 border-teal-500"
      style={{ marginTop: "30px" }}
    >
      <ScrollToTop />
      <div className="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-6xl lg:px-8">
        <h1 className="text-3xl sm:text-5xl font-bold text-teal-100">
          Data Visualization
        </h1>
        <p className="text-md sm:text-xl mt-5 text-gray-300">
          In a contemporary society, data plays a crucial role in enhancing our
          lives, guiding decisions, discovering solutions, among other things.
          Data visualization is the process of translating data into graphical
          representations, enabling us to understand complex information and
          patterns more effectively.
        </p>

        <div className="mt-16 space-y-24">
          {servicesData.map((services, servicesIdx) => (
            <div
              key={services.title}
              className={`flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8 ${animationClass}`}
              style={{ animationDelay: `${servicesIdx * 0.3}s` }}
            >
              <div
                className={classNames(
                  servicesIdx % 2 === 0
                    ? "lg:col-start-1"
                    : "lg:col-start-8 xl:col-start-9",
                  "mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4"
                )}
              >
                <h3 className="sm:text-4xl text-3xl font-medium text-teal-100">
                  {services.title}
                </h3>
                <p className="mt-2 text-lg text-gray-200 font-light">
                  {services.description}
                </p>
              </div>
              <div
                className={classNames(
                  servicesIdx % 2 === 0
                    ? "lg:col-start-6 xl:col-start-5"
                    : "lg:col-start-1",
                  "flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8"
                )}
              >
                <div className="aspect-h-2 aspect-w-5 h-1/2 w-1/2 overflow-hidden opacity-85">
                  <img
                    src={services.image}
                    alt={""}
                    className="object-cover object-center"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
