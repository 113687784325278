import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo1.webp";

export default function Header() {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const location = useLocation(); // Hook to access the current route location
  const navRef = useRef(); // Ref for the navbar

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLinkClick = () => {
    setIsNavVisible(false);
    scrollToTop();
  };

  const scrollToId = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const navbarHeight = 70; // Height of your navbar
      const offsetPosition = section.offsetTop - navbarHeight; // Offset position to not hide behind the navbar

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };
  const handleNavLinkClick = (id) => {
    setIsNavVisible(false); // Close the mobile navigation menu
    if (location.pathname !== "/") {
      window.location.href = "/"; // Navigate to home and then to the ID
    } else {
      scrollToId(id); // Scroll to the ID on the same page
    }
  };

  // Effect to handle clicks outside the navbar
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isNavVisible &&
        navRef.current &&
        !navRef.current.contains(event.target)
      ) {
        setIsNavVisible(false); // Close the navbar if click is outside
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isNavVisible]); // Only re-run the effect if isNavVisible changes

  return (
    <header className="bg-white fixed top-0 left-0 py-6 px-4 sm:px-8 flex justify-between items-center w-full border-b-4 border-teal-400 z-20">
      <div className="">
        <Link to="/" onClick={scrollToTop}>
          <img src={logo} alt="Logo" className="h-8 px-2 ml-2 sm:ml-12" />
        </Link>
      </div>

      <div
        ref={navRef}
        className={`absolute inset-x-0 top-0 p-4 sm:p-0 sm:relative ${
          isNavVisible ? "block" : "hidden"
        }`}
      >
        <nav className="flex flex-col border-2 border-teal-200 text-teal-600 items-center space-y-4 text-sm uppercase bg-white shadow-md rounded-lg py-4">
          <div className="sm:hidden hidden">
            <Link to="/" onClick={handleLinkClick}>
              <img
                src={logo}
                alt="Logo"
                className="h-8 px-2 ml-2 rounded-2xl mt-8"
              />
            </Link>
          </div>

          {/* Conditionally render navigation links */}
          {location.pathname === "/" ? (
            <>
              {/* <Link
                to="/"
                onClick={() => handleNavLinkClick("agile")}
                className="hover:text-gray-700"
              >
                Agile
              </Link>
              <Link
                to="/"
                onClick={() => handleNavLinkClick("what-we-offer")}
                className="hover:text-gray-700"
              >
                What We Offer
              </Link> */}
            </>
          ) : (
            <>
              {/* <Link
                to="/"
                onClick={handleLinkClick}
                className="hover:text-gray-700"
              >
                Agile
              </Link>
              <Link
                to="/"
                onClick={handleLinkClick}
                className="hover:text-gray-700"
              >
                What We Offer
              </Link> */}
            </>
          )}

          {/* <Link
            to="/how-we-work"
            onClick={handleLinkClick}
            className="hover:text-gray-700"
          >
            Why Eagle
          </Link>
          <Link
            to="/company"
            onClick={handleLinkClick}
            className="hover:text-gray-700"
          >
            Team
          </Link> */}
          {/* <Link
            to="/webdemo"
            onClick={handleLinkClick}
            className="hover:text-gray-700"
          >
            Bot
          </Link> */}
          {/* <Link to="/contact" onClick={handleLinkClick}>
            <button className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-teal-700 sm:mr-12">
              Book a Meeting
            </button>
          </Link> */}
        </nav>
      </div>

      <nav className="hidden sm:flex flex-grow text-semibold text-teal-700 justify-center items-center space-x-6 uppercase z-10">
        {/* Conditionally render navigation links */}
        {location.pathname === "/" ? (
          <>
            {/* <Link
              to="/"
              onClick={() => handleNavLinkClick("agile")}
              className="hover:text-gray-700"
            >
              Agile
            </Link>
            <Link
              to="/"
              onClick={() => handleNavLinkClick("what-we-offer")}
              className="hover:text-gray-700"
            >
              What We Offer
            </Link> */}
          </>
        ) : (
          <>
            {/* <Link to="/" className="hover:text-gray-700">
              Agile
            </Link>
            <Link to="/" className="hover:text-gray-700">
              What We Offer
            </Link> */}
          </>
        )}

        {/* <Link
          to="/how-we-work"
          onClick={scrollToTop}
          className="hover:text-gray-700"
        >
          Why Eagle
        </Link>

        <Link
          to="/company"
          onClick={scrollToTop}
          className="hover:text-gray-700"
        >
          Team
        </Link> */}
        {/* <Link
          to="/webdemo"
          onClick={scrollToTop}
          className="hover:text-gray-700"
        >
          Bot
        </Link> */}
      </nav>

      <Link to="/" onClick={() => handleNavLinkClick("meeting")}>
        <button className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-teal-700 sm:mr-12 sm:block">
          Welcome!
        </button>
      </Link>

      {/* <div className="sm:hidden flex items-center">
        <button
          className="text-gray-800 text-3xl mb-1"
          onClick={() => setIsNavVisible(!isNavVisible)}
        >
          ☰
        </button>
      </div> */}
    </header>
  );
}
