import React from "react";
import ScrollToTop from "./ScrollToTop";
//import { motion } from "framer-motion";
//import { useInView } from "react-intersection-observer";
//import emailjs from "@emailjs/browser";
//import { toast } from "react-toastify";
//import { useNavigate } from "react-router-dom";
import Cards from "./Cards";

// Hardcoded values for testing
// const SERVICE_ID = "service_w1z4yvp";
// const TEMPLATE_ID = "template_wtsqpyn";
// const USER_ID = "9ERJ5j4yIoGe9Uxpq";

const HowWeWork = () => {
  // const { ref, inView } = useInView({
  //   triggerOnce: true,
  //   threshold: 0.1,
  // });

  // const form = useRef();
  // const navigate = useNavigate();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   // Retrieve form values
  //   const firstName = form.current["first-name"].value;
  //   const email = form.current["email"].value;
  //   const message = form.current["message"].value;

  //   // Check that all required fields are filled out
  //   if (!firstName || !email || !message /* other fields... */) {
  //     toast.error("All fields must be filled out.");
  //     return; // Exit function if not all fields are filled out
  //   }

  //   emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID).then(
  //     () => {
  //       toast.success("Message sent successfully! We'll be in touch.");
  //       form.current.reset();
  //       navigate("/");
  //     },
  //     () => {
  //       toast.error(
  //         "Something went wrong. Please try again or call us directly"
  //       );
  //     }
  //   );
  // };

  return (
    <div className="bg-neutral-800 ">
      <ScrollToTop />
      <Cards />
      <div className="flex flex-wrap ">
        {/* Content Section */}
        {/* <div className="w-full md:w-1/2 p-4">
          <div className="max-w-screen-md mx-auto py-12 px-4 sm:px-6 md:py-20 lg:py-32">
            <motion.div
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
              }}
            >
              <h1 className="text-2xl text-gray-300 font-bold md:text-3xl lg:text-3xl">
                Solving problems for every{" "}
                <span className="text-teal-400">Team!</span>
              </h1>
              <p className="mt-3 text-base text-gray-300 pl-4 border-l-4 border-teal-400 py-2">
                Built on Technology, Innovation, and Collaboration!{" "}
              </p>
            </motion.div>
          </div>
        </div> */}

        {/* Contact Form Section
        <div className="w-full md:w-1/2 p-4" id="meeting">
          <div className="max-w-md sm:mt-28 mx-auto bg-neutral-800 p-8 ">
            <form ref={form} onSubmit={sendEmail} className="space-y-6">
              <input
                type="text"
                name="first-name"
                placeholder="Full name"
                className="w-full p-4 rounded bg-white/10 text-white"
              />

              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                className="w-full p-4 rounded bg-white/10 text-white"
              />
              <textarea
                name="message"
                placeholder="Message"
                rows="1"
                id="message"
                className="w-full p-4 rounded bg-white/10 text-white"
              />
              <button
                type="submit"
                className="w-full bg-teal-700 py-3 rounded hover:bg-teal-600 transition-colors text-white font-bold"
              >
                Book a meeting
              </button>
            </form>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default HowWeWork;
