import React from "react";
import Jeremy from "../assets/jeremy.png";
import Lusett from "../assets/lusett.png";
import Miguel from "../assets/miguel.png";
//import image1 from "../assets/footer.webp";
import ScrollToTop from "./ScrollToTop";
//import Evelyn from "../assets/evelyn.webp";

const Company = () => {
  return (
    <div
      className="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto bg-neutral-800 border-b-4 border-teal-500"
      style={{ marginTop: "90px" }}
    >
      <ScrollToTop />

      <section class="bg-neutral-800 mb-20">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
          <div class="mx-auto max-w-screen-sm text-center mb-10 lg:mb-16">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-teal-500 ">
              Family-Led Executive Board
            </h2>
            <p class="font-light lg:mb-20 sm:text-x text-gray-300">
              Meet the Team at Eagle Engineering Systems!
            </p>
          </div>
          <div class="grid gap-20 mb-4 lg:mb-4 md:grid-cols-2 w-3/4 h-auto sm:w-full sm:h-full mx-auto ">
            <div class="items-center bg-gray-50 rounded-lg shadow sm:flex ">
              <a
                href="https://linkedin.com/in/jeremyescobar"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  class="w-full rounded-t-lg sm:rounded-none sm:rounded-l-lg border-b-4 border-teal-500 sm:border-b-0"
                  src={Jeremy}
                  alt="Jeremys Avatar"
                ></img>
              </a>
              <div class="p-5">
                <h3 class="text-xl font-bold tracking-tight text-neutral-700 ">
                  <a
                    href="https://linkedin.com/in/jeremyescobar"
                    target="_blank"
                    rel="nooopener noreferrer"
                  >
                    Jeremy Escobar
                  </a>
                </h3>
                <span class="text-gray-600 text-bold">FOUNDER / CEO </span>
                <p class="mt-3 mb-4 font-light text-gray-500 hidden sm:block">
                  Computer and Electronics Engineer, driving the vision of the
                  company.
                </p>
                <ul class="flex space-x-4 sm:mt-0"></ul>
              </div>
            </div>
            <div class="items-center bg-gray-50 rounded-lg shadow sm:flex ">
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="nooopener noreferrer"
              >
                <img
                  class="w-full rounded-t-lg sm:rounded-none sm:rounded-l-lg  border-b-4 border-teal-500 sm:border-b-0"
                  src={Lusett}
                  alt="Lusett Avatar"
                ></img>
              </a>
              <div class="p-5">
                <h3 class="text-xl font-bold tracking-tight text-gray-900 ">
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="nooopener noreferrer"
                  >
                    Lusett Escobar-Wood
                  </a>
                </h3>
                <span class="text-gray-600 text-bold">
                  CHIEF OPERATING OFFICER{" "}
                </span>
                <p class="mt-3 mb-4 font-light text-gray-500 hidden sm:block">
                  Driving company strategy and ensuring smooth operation of
                  business.
                </p>
                <ul class="flex space-x-4 sm:mt-0"></ul>
              </div>
            </div>
            <div class="items-center bg-gray-50 rounded-lg shadow sm:flex ">
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="nooopener noreferrer"
              >
                <img
                  class="w-full rounded-t-lg sm:rounded-none sm:rounded-l-lg  border-b-4 border-teal-500 sm:border-b-0"
                  src={Miguel}
                  alt="Miguel Avatar"
                ></img>
              </a>
              <div class="p-5">
                <h3 class="text-xl font-bold tracking-tight text-gray-900 ">
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="nooopener noreferrer"
                  >
                    Miguel Escobar
                  </a>
                </h3>
                <span class="text-gray-600 text-bold">CO-FOUNDER / CMO </span>
                <p class="mt-3 mb-4 font-light text-gray-500 hidden sm:block">
                  Marketing and Sales expert, driving company growth and
                  expansion.
                </p>
                <ul class="flex space-x-4 sm:mt-0"></ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="relative flex items-center justify-center h-full">
        <div className="text-center text-gray-300 p-4">
          <h1 className="text-4xl font-bold mb-3">Follow us on Linkedin</h1>
          <button className=" font-bold py-2 px-4 rounded">
            <div class="flex flex-wrap ">
              <a
                href="https://www.linkedin.com/company/eagle-engineering-systems"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  class="py-3 lg:py-5 w-20 h-auto md:w-20 lg:w-24 text-teal-600 ml-8 sm:ml-0"
                  viewBox="0 -8.881784197001252e-16 267.51517722360785 65.24679557585003"
                  xmlns="http://www.w3.org/2000/svg"
                  width="2500"
                  height="610"
                >
                  <path
                    d="M263.043 56.411a4.418 4.418 0 1 0 .085 0zm0 8.33a3.874 3.874 0 1 1 3.809-3.938v.065a3.791 3.791 0 0 1-3.708 3.871h-.1m-16.96-9.535h-9.6V40.17c0-3.585-.064-8.2-4.993-8.2-5 0-5.765 3.906-5.765 7.939v15.294h-9.6V24.287h9.216v4.225h.129a10.1 10.1 0 0 1 9.093-4.994c9.73 0 11.524 6.4 11.524 14.726zm-40.79-35.143a5.571 5.571 0 1 1 5.57-5.572 5.571 5.571 0 0 1-5.57 5.572m4.8 35.143h-9.61V24.287h9.61zM250.87.004h-55.21a4.728 4.728 0 0 0-4.781 4.67v55.439a4.731 4.731 0 0 0 4.781 4.675h55.21a4.741 4.741 0 0 0 4.8-4.675V4.67a4.738 4.738 0 0 0-4.8-4.67m-86.12 31.749c-4.8 0-7.68 3.205-7.68 7.875s2.879 7.878 7.68 7.878 7.687-3.2 7.687-7.878-2.881-7.875-7.687-7.875m16.525 23.437h-8.838v-4.1h-.131a12.071 12.071 0 0 1-9.544 4.868c-9.224 0-15.3-6.657-15.3-16.071s7.685-16.585 14.216-16.585c3.973 0 7.684 1.087 9.861 4.1h.126V9.577h9.609zm-46.139-19.048a5.756 5.756 0 0 0-5.894-5.89 6.406 6.406 0 0 0-6.784 5.89zm8.132 13.7a16.909 16.909 0 0 1-13.128 6.151c-9.6 0-17.286-6.408-17.286-16.331s7.685-16.328 17.286-16.328c8.973 0 14.6 6.4 14.6 16.328v3.01h-22.282a7.171 7.171 0 0 0 7.235 6.019 8.193 8.193 0 0 0 6.851-3.778zM47.834 24.279h9.219v4.225h.131a10.085 10.085 0 0 1 9.09-4.994c9.735 0 11.527 6.405 11.527 14.726V55.19h-9.6V40.159c0-3.588-.066-8.2-5-8.2-4.99 0-5.76 3.907-5.76 7.939v15.288h-9.6zM82.669 9.58h9.6v27.265l10.88-12.583h11.77l-12.6 14.313 12.335 16.63h-12.066L92.397 39.923h-.126v15.28h-9.6zM32.911 24.276h9.6v30.916h-9.6zm4.8-15.37a5.569 5.569 0 1 1-5.57 5.569 5.569 5.569 0 0 1 5.57-5.569M0 9.587h9.993v36.4h18.5v9.222H0zm263.744 51.522a1.2 1.2 0 0 0 1.21-1.269c0-.9-.543-1.33-1.657-1.33h-1.8v4.712h.677v-2.054h.832l.019.025 1.291 2.029h.724l-1.389-2.1zm-.783-.472h-.785v-1.593h.995c.514 0 1.1.084 1.1.757 0 .774-.593.836-1.314.836"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Company;
